import { graphql, Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      background: ${colors.darkmode};
    }
  }
`;

interface TagsProps {
  data: {
    allTagYaml: {
      distinct: string[];
    };
  };
}

const upperFirstLetter = (word: string): string => {
  return word[0].toUpperCase() + word.slice(1);
};

const Tags: React.FC<TagsProps> = props => {
  const tags = props.data.allTagYaml.distinct;
  return (
    <IndexLayout>
      <Helmet>
        <title>Tags</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page" css={[PostFull, NoImage]}>
              <PostFullHeader>
                <PostFullTitle>Tags</PostFullTitle>
              </PostFullHeader>
              <PostFullContent>
                <p>
                  What do you fancy today? Curious to see everything related to{' '}
                  <Link to="/tags/baking">baking</Link>? Or maybe you would like to have a pick at
                  some of our <Link to="/tags/travel">trips</Link>? Why not using tags to navigate
                  through blog posts?
                </p>
                <TagsContainer>
                  {tags.map(tag => {
                    return (
                      <TagsLinks key={tag}>
                        <Link to={`/tags/${tag}`}>{upperFirstLetter(tag)}</Link>
                      </TagsLinks>
                    );
                  })}
                </TagsContainer>
              </PostFullContent>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagsLinks = styled.div`
  text-decoration: none;
  color: ${colors.pink};
  width: 50%;
`;

export const allTagsQuery = graphql`
  query {
    allTagYaml {
      distinct(field: id)
    }
  }
`;

export default Tags;
